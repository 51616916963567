@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700");
@media (min-width: 0) {
  .card-deck .card {
    flex-grow: 0 !important;
    flex-shrink: 0 !important;
    flex-basis: calc( 100% - 30px) !important; } }

@media (min-width: 576px) {
  .card-deck .card {
    flex-grow: 0 !important;
    flex-shrink: 0 !important;
    flex-basis: calc( 100% - 30px) !important; } }

@media (min-width: 768px) {
  .card-deck .card {
    flex-grow: 0 !important;
    flex-shrink: 0 !important;
    flex-basis: calc( 50% - 30px) !important; } }

@media (min-width: 992px) {
  .card-deck .card {
    flex-grow: 0 !important;
    flex-shrink: 0 !important;
    flex-basis: calc( 33.33333% - 30px) !important; } }

@media (min-width: 1200px) {
  .card-deck .card {
    flex-grow: 0 !important;
    flex-shrink: 0 !important;
    flex-basis: calc( 33.33333% - 30px) !important; } }

::-webkit-input-placeholder, :-ms-input-placeholder, ::-moz-placeholder {
  color: #999 !important; }

:focus::-webkit-input-placeholder, :focus:-ms-input-placeholder, :focus::-moz-placeholder {
  color: #555 !important; }

body {
  margin: 0 auto;
  height: 100vh;
  overflow-y: scroll !important;
  background-color: #fbf9f9 !important; }

.u-margin-0 {
  margin: 0 !important; }

.u-padding-0 {
  padding: 0 !important; }

#root {
  height: 100%; }
  #root .App {
    height: 100%; }
    #root .App > div {
      height: 100%; }

a {
  cursor: pointer;
  color: #0073DC;
  text-decoration: none !important; }

h5 {
  color: #0073DC !important; }

button.save.dropdown-toggle.btn.btn-secondary {
  color: #FFFFFF !important; }

.btn, .btn-secondary {
  color: #0073DC !important;
  border-color: #0073DC !important; }

.tabList button.active, .tabList button:hover {
  color: #0073DC !important;
  border-color: #0073DC !important; }

.btn:hover {
  color: white !important;
  background-color: #0073DC !important; }

.cta button {
  background-color: #0073DC !important; }

button.dropdown-item {
  background-color: white !important;
  color: #0073DC !important; }

footer > div:first-child {
  padding: 20px;
  margin-top: 20px; }
  footer > div:first-child > div:first-child {
    text-align: center;
    width: 100%; }
    footer > div:first-child > div:first-child .poweredBy {
      font-size: 13px;
      line-height: 13px;
      font-weight: 400;
      padding: 4px 0 0 5px;
      color: #999;
      display: inline-block;
      position: relative;
      white-space: nowrap; }
      footer > div:first-child > div:first-child .poweredBy:before {
        content: "";
        position: absolute;
        top: -8px;
        left: -85px;
        width: calc(100% + 175px);
        height: 1px;
        background: #e6e6e6;
        background: -webkit-gradient(linear, 0 0, 100% 0, from(#fbf9f9), to(#fbf9f9), color-stop(50%, #ddd)); }
      footer > div:first-child > div:first-child .poweredBy a {
        color: #00b3e8; }
        footer > div:first-child > div:first-child .poweredBy a:hover {
          color: #666; }

.opportunity {
  margin-top: 10rem;
  max-width: 800px; }
  .opportunity img {
    width: 60px; }
  .opportunity .inputOpp {
    display: flex;
    flex-direction: row; }
    .opportunity .inputOpp .flat {
      background-color: white; }
    .opportunity .inputOpp .invalid > div, .opportunity .inputOpp .invalid > input {
      border: red solid 1px; }
    .opportunity .inputOpp .input {
      margin-top: 10px; }
    .opportunity .inputOpp .right {
      width: 50%;
      padding-left: 10px;
      margin-left: 10px;
      border-left: grey 2px solid; }
    .opportunity .inputOpp .left {
      width: 50%; }
  .opportunity .submit {
    background-color: lightblue;
    color: white;
    margin-top: 10px;
    width: 100%; }

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  z-index: 99999;
  opacity: 0.25; }

.dimmed {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); }

.backButton {
  display: block;
  margin: -10px 0 20px 0px;
  font-size: 16px;
  font-weight: 300;
  font-style: italic;
  -webkit-transition: color 200ms;
  -o-transition: color 200ms;
  transition: color 200ms;
  text-decoration: none; }
  .backButton svg {
    margin-right: 6px; }

.detail.container .card .card-body {
  font-size: 13px;
  padding-bottom: 20px; }
  .detail.container .card .card-body h1, .detail.container .card .card-body h2, .detail.container .card .card-body h3, .detail.container .card .card-body h4, .detail.container .card .card-body h5, .detail.container .card .card-body h6 {
    font-size: initial;
    font-weight: initial;
    line-height: initial; }
  .detail.container .card .card-body .section {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #eee;
    /*&:last-child, &.checkbox, &.radio {
          border-top: 1px solid #eee;
          padding-top: 35px;
          margin-top: 35px;
        }*/ }
    .detail.container .card .card-body .section select {
      margin-left: 15px; }
    .detail.container .card .card-body .section > h2, .detail.container .card .card-body .section .label {
      margin-bottom: 10px;
      font-size: 15px; }
    .detail.container .card .card-body .section .form-control {
      width: 200px;
      margin-top: 6px;
      color: #555; }
    .detail.container .card .card-body .section .dropdown > button {
      border-color: #ced4da;
      color: #999; }
      .detail.container .card .card-body .section .dropdown > button.selected {
        color: #555; }
      .detail.container .card .card-body .section .dropdown > button:hover {
        background-color: #fff; }
      .detail.container .card .card-body .section .dropdown > button:focus {
        border-color: #80bdff;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        color: #555; }
    .detail.container .card .card-body .section .dropdown.show > button {
      background-color: #fff; }
    .detail.container .card .card-body .section .dropdown .dropdown-menu > button {
      font-size: 13px; }
      .detail.container .card .card-body .section .dropdown .dropdown-menu > button:focus {
        outline: 0; }
      .detail.container .card .card-body .section .dropdown .dropdown-menu > button:active {
        background-color: #fff;
        color: #333; }
    .detail.container .card .card-body .section .invalid > button {
      border-color: red; }
    .detail.container .card .card-body .section .checkoption {
      height: 25px;
      color: #999; }
      .detail.container .card .card-body .section .checkoption > div {
        display: inline-block; }
        .detail.container .card .card-body .section .checkoption > div input[type=checkbox] {
          -webkit-appearance: none;
          background-color: #fafafa;
          border: 1px solid #cacece;
          padding: 9px;
          border-radius: 3px;
          display: inline-block;
          position: relative;
          margin-right: 10px;
          bottom: -5px; }
          .detail.container .card .card-body .section .checkoption > div input[type=checkbox]:active {
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px 1px 3px rgba(0, 0, 0, 0.1); }
          .detail.container .card .card-body .section .checkoption > div input[type=checkbox]:checked {
            background-color: #e9ecee;
            border: 1px solid #adb8c0;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05), inset 15px 10px -12px rgba(255, 255, 255, 0.1);
            color: #99a1a7; }
            .detail.container .card .card-body .section .checkoption > div input[type=checkbox]:checked:after {
              content: '\2714';
              font-size: 14px;
              position: absolute;
              top: 0px;
              left: 3px;
              color: #99a1a7; }
          .detail.container .card .card-body .section .checkoption > div input[type=checkbox]:focus {
            outline: 0; }
        .detail.container .card .card-body .section .checkoption > div:hover {
          cursor: pointer;
          color: #555; }
          .detail.container .card .card-body .section .checkoption > div:hover input[type=checkbox] {
            border-color: #aaa; }
      .detail.container .card .card-body .section .checkoption.checked {
        color: #555; }
      .detail.container .card .card-body .section .checkoption .dropdown {
        display: inline-block;
        padding-left: 10px; }
        .detail.container .card .card-body .section .checkoption .dropdown > button {
          padding: 1px 6px;
          font-size: 10px; }

.login {
  padding: 15px;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around; }
  .login .back, .login .forgot {
    width: 100%;
    padding: 0;
    color: blue;
    background-color: #ffffff;
    border: none; }
  .login input {
    width: 250px !important; }
  .login button {
    width: 100%; }
  .login .alert-danger {
    margin-top: 3px;
    margin-bottom: 0; }

.site .navbar {
  width: 100%; }
  .site .navbar .logo {
    height: 50px;
    width: auto;
    position: relative;
    z-index: 99999; }

.site > .container-fluid {
  padding: 0; }

.site.private .navbar {
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px; }
  .site.private .navbar > .container > .row {
    margin: 0 auto; }

.site.list .page .card {
  /*margin-bottom: 20px;*/
  border-radius: 0; }
  .site.list .page .card:hover {
    cursor: pointer;
    border-color: #cacdd0;
    background-color: #fcfcfc;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 8px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 8px;
    -moz-box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 8px; }
  .site.list .page .card .card-header {
    background: none;
    border-bottom: 0;
    padding-bottom: 0; }
    .site.list .page .card .card-header .card-title {
      font-size: 17px;
      font-weight: 500;
      padding-top: 20px;
      padding-bottom: 12px; }
  .site.list .page .card .card-body {
    padding-top: 0;
    padding-bottom: 0; }
    .site.list .page .card .card-body .card-text {
      font-size: 12px; }
  .site.list .page .card .card-footer {
    border-top: 0;
    background: none;
    padding: 0; }
    .site.list .page .card .card-footer button {
      border: 0;
      border-radius: 0;
      font-size: 16px; }

.site.detail .navbar .logo {
  top: 0; }

.site.proposal .navbar {
  /*margin-bottom: 50px;*/
  border-bottom: 1px solid #ccc; }
  .site.proposal .navbar .logo {
    top: 0; }

.priceTime {
  font-size: 16px;
  line-height: 1;
  font-weight: 400;
  margin-bottom: 14px;
  color: #666666; }
  .priceTime span + span:before {
    content: "|";
    font-weight: 300;
    color: #999;
    position: relative;
    margin: 0 5px; }

.detail.container {
  padding-top: 50px; }
  .detail.container > .btn-link {
    display: block;
    margin: 20px 0 20px -12px;
    color: #999;
    font-size: 15px;
    font-style: italic;
    -webkit-transition: color 200ms;
    -moz-transition: color 200ms;
    -o-transition: color 200ms;
    transition: color 200ms; }
    .detail.container > .btn-link:focus {
      outline: 0; }
    .detail.container > .btn-link:hover {
      color: #666;
      text-decoration: none; }
    .detail.container > .btn-link svg {
      margin-right: 6px; }
  .detail.container > .card {
    margin-bottom: 20px; }
    .detail.container > .card .productDetailTabDisplay {
      padding-top: 20px; }
      .detail.container > .card .productDetailTabDisplay .tabList {
        border-bottom: 1px solid #e9e9e9; }
        .detail.container > .card .productDetailTabDisplay .tabList button {
          border-radius: 0;
          border-bottom: 1px solid white;
          color: #aaa;
          position: relative;
          font-size: 14px;
          font-weight: 400;
          padding: 14px 0;
          cursor: pointer;
          outline: none;
          border: none;
          background-color: #fff;
          margin-right: 50px; }
          .detail.container > .card .productDetailTabDisplay .tabList button:hover {
            color: #666; }
          .detail.container > .card .productDetailTabDisplay .tabList button:focus {
            outline: 0; }
          .detail.container > .card .productDetailTabDisplay .tabList button.active {
            color: #666; }
            .detail.container > .card .productDetailTabDisplay .tabList button.active:before {
              content: "";
              width: 0;
              height: 0;
              border-left: 10px solid transparent;
              border-right: 10px solid transparent;
              border-bottom: 10px solid #eaeaea;
              position: absolute;
              left: 0;
              right: 0;
              bottom: -2px;
              margin: auto; }
            .detail.container > .card .productDetailTabDisplay .tabList button.active:after {
              content: "";
              width: 0;
              height: 0;
              border-left: 8px solid transparent;
              border-right: 8px solid transparent;
              border-bottom: 8px solid #fff;
              position: absolute;
              left: 0;
              right: 0;
              bottom: -2px;
              margin: auto; }
      .detail.container > .card .productDetailTabDisplay .tabContent {
        padding-top: 30px; }
        .detail.container > .card .productDetailTabDisplay .tabContent .invalid {
          border: red solid 1px; }
        .detail.container > .card .productDetailTabDisplay .tabContent .section:first-child {
          padding-top: 0;
          margin-top: 0;
          border-top: 0; }
        .detail.container > .card .productDetailTabDisplay .tabContent .faqs {
          list-style: none;
          padding: 0; }
          .detail.container > .card .productDetailTabDisplay .tabContent .faqs .faq {
            color: #666;
            margin-bottom: 20px;
            font-size: 13px;
            padding: 0; }
            .detail.container > .card .productDetailTabDisplay .tabContent .faqs .faq .question {
              font-weight: bold; }
            .detail.container > .card .productDetailTabDisplay .tabContent .faqs .faq:last-child {
              margin-bottom: 0; }

/* CUSTOM */
* {
  font-family: "Open sans",sans-serif !important; }

.site {
  /*background: url(../assets/background.svg) 50% repeat;
  background-attachment: fixed;
  background-size: cover;*/
  height: 100vh; }
  .site .navbar {
    width: 100%;
    background: #fff !important;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }
    .site .navbar > .container {
      position: relative; }
      .site .navbar > .container > .row {
        width: 100%; }
      .site .navbar > .container .logo {
        top: 0; }
      .site .navbar > .container .navbar-brand {
        z-index: 1; }
      .site .navbar > .container .navbar-nav {
        flex-direction: row; }
        .site .navbar > .container .navbar-nav li + li {
          margin-left: 40px; }
        .site .navbar > .container .navbar-nav li a, .site .navbar > .container .navbar-nav li button {
          line-height: 55px;
          background-color: transparent;
          color: #999;
          border: 0;
          padding: 0; }
        .site .navbar > .container .navbar-nav li a:focus, .site .navbar > .container .navbar-nav li button:focus {
          outline: 0;
          box-shadow: none; }
        .site .navbar > .container .navbar-nav li a:hover, .site .navbar > .container .navbar-nav li a.active {
          color: #00afcc; }
        .site .navbar > .container .navbar-nav li button:hover, .site .navbar > .container .navbar-nav li button.active {
          color: #00afcc; }
  .site .login .back, .site .login .forgot {
    color: #00afcc;
    background-color: transparent; }
  .site .login .back:focus, .site .login .forgot:focus {
    background-color: transparent;
    outline: 0;
    box-shadow: none; }
  .site > .container-fluid {
    position: relative;
    height: 100vh; }
    .site > .container-fluid:before {
      content: "";
      /* background: url(../assets/background.svg) 50% repeat; */
      background-attachment: fixed;
      background-size: cover;
      opacity: 0.3;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1; }
  .site .dropdown .dropdown-menu .dropdown-item {
    color: #5C6770;
    padding: 4px 15px; }
    .site .dropdown .dropdown-menu .dropdown-item:hover {
      cursor: pointer;
      color: #00a6c2; }
    .site .dropdown .dropdown-menu .dropdown-item:focus {
      outline: 0; }
    .site .dropdown .dropdown-menu .dropdown-item:active {
      background-color: #f8f9fa; }
    .site .dropdown .dropdown-menu .dropdown-item.disabled {
      color: #bbb; }
  .site .page .hero {
    /* background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiP…Igd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g) !important; */
    /* background-image: -webkit-gradient(linear, 0 0, 100% 100%, color-stop(0, #1d4189), color-stop(61%, #00b2e2)) !important; */
    /* background-image: -webkit-linear-gradient(left top, #1d4189 0, #00b2e2 61%) !important; */
    /* background-image: -webkit-gradient(linear, left top, right bottom, from(#1d4189), color-stop(61%, #00b2e2)) !important; */
    /* background-image: -o-linear-gradient(left top, #1d4189 0, #00b2e2 61%) !important; */
    /* background-image: linear-gradient(to right bottom, #1d4189 0, #00b2e2 61%) !important; */
    /* background-image: linear-gradient(270deg,#00c9ff 0,#09f); */
    background-image: linear-gradient(90deg, #0073dc 0%, #09f 100%);
    position: relative;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 0; }
    .site .page .hero > div {
      position: relative;
      width: 100%;
      height: 330px;
      padding: 70px 200px 70px 0;
      margin: 0 auto; }
      .site .page .hero > div:after {
        position: absolute;
        display: block;
        background: url(../assets/namely-logo.png) no-repeat;
        background-size: cover;
        content: '';
        top: 50%;
        right: 15px;
        height: 200px;
        width: 205px;
        transform: translateY(-50%); }
      .site .page .hero > div .titles {
        position: relative;
        z-index: 9999;
        left: 0;
        color: #fff;
        display: block; }
        .site .page .hero > div .titles .row {
          display: block; }
        .site .page .hero > div .titles .title {
          color: #fff;
          font-weight: 300;
          font-size: 37px;
          line-height: 45px;
          margin-bottom: 25px;
          font-family: "Roboto", sans-serif; }
        .site .page .hero > div .titles .subtitle {
          color: #fff;
          font-weight: 200;
          font-size: 20px;
          line-height: 26px;
          display: block; }
      .site .page .hero > div > .container {
        padding-top: 450px; }
        .site .page .hero > div > .container .featured {
          position: relative;
          z-index: 9999;
          margin: 0 -30px 25px -30px; }
          .site .page .hero > div > .container .featured .product-featured .card {
            border: 0;
            border-radius: 0;
            color: #fff;
            text-align: center;
            background: rgba(29, 29, 29, 0.8);
            /*&.blue {
                    .card-header {
                      &:before {
                        background-color: $color-blue;
                      }
                    }
                    .card-footer {
                      button {
                        background-color: $color-blue;
                      }
                    }
                    &:hover {
                      .card-header {
                        &:before {
                          background-color: $color-blue-hover;
                        }
                      }
                      .card-footer {
                        button {
                          background-color: $color-blue-hover;
                        }
                      }
                    }
                  }
                  &.orange {
                    .card-header {
                      &:before {
                        background-color: $color-orange;
                      }
                    }
                    .card-footer {
                      button {
                        background-color: $color-orange;
                      }
                    }
                    &:hover {
                      .card-header {
                        &:before {
                          background-color: $color-orange-hover;
                        }
                      }
                      .card-footer {
                        button {
                          background-color: $color-orange-hover;
                        }
                      }
                    }
                  }
                  &.green {
                    .card-header {
                      &:before {
                        background-color: $color-green;
                      }
                    }
                    .card-footer {
                      button {
                        background-color: $color-green;
                      }
                    }
                    &:hover {
                      .card-header {
                        &:before {
                          background-color: $color-green-hover;
                        }
                      }
                      .card-footer {
                        button {
                          background-color: $color-green-hover;
                        }
                      }
                    }
                  }*/ }
            .site .page .hero > div > .container .featured .product-featured .card .card-header {
              position: relative;
              padding-top: 40px; }
              .site .page .hero > div > .container .featured .product-featured .card .card-header:before {
                position: absolute;
                content: "";
                top: -25px;
                left: 50%;
                transform: translateX(-50%);
                -moz-transform: translateX(-50%);
                -webkit-transform: translateX(-50%);
                -o-transform: translateX(-50%);
                width: 60px;
                height: 60px;
                border-radius: 50%; }
              .site .page .hero > div > .container .featured .product-featured .card .card-header:after {
                position: absolute;
                content: "";
                top: -25px;
                left: 50%;
                transform: translateX(-50%);
                -moz-transform: translateX(-50%);
                -webkit-transform: translateX(-50%);
                -o-transform: translateX(-50%);
                width: 60px;
                height: 60px;
                border-radius: 50%;
                background: url(../assets/featured-icon.png) center center no-repeat;
                background-size: auto 25px; }
              .site .page .hero > div > .container .featured .product-featured .card .card-header .card-title {
                padding-bottom: 0;
                margin-bottom: 0; }
              .site .page .hero > div > .container .featured .product-featured .card .card-header .card-subtitle {
                margin-top: 12px; }
            .site .page .hero > div > .container .featured .product-featured .card .card-body {
              display: none; }
            .site .page .hero > div > .container .featured .product-featured .card .card-footer {
              padding-top: 0; }
              .site .page .hero > div > .container .featured .product-featured .card .card-footer button {
                position: absolute;
                bottom: -15px;
                left: 50%;
                transform: translateX(-50%);
                -moz-transform: translateX(-50%);
                -webkit-transform: translateX(-50%);
                -o-transform: translateX(-50%); }
            .site .page .hero > div > .container .featured .product-featured .card:hover {
              background: rgba(29, 29, 29, 0.9); }
              .site .page .hero > div > .container .featured .product-featured .card:hover .card-body {
                display: block;
                padding-bottom: 15px; }
          .site .page .hero > div > .container .featured .product-featured .priceTime {
            color: #e3e6e8; }
      .site .page .hero > div .categories {
        position: relative;
        z-index: 999; }
        .site .page .hero > div .categories .col {
          padding: 0 2px; }
          .site .page .hero > div .categories .col > div {
            background-color: rgba(249, 247, 244, 0.8);
            padding: 10px;
            width: 100%;
            height: 100%;
            text-align: center;
            position: relative; }
            .site .page .hero > div .categories .col > div:before, .site .page .hero > div .categories .col > div:after {
              content: "";
              position: absolute;
              top: 0;
              width: 100%;
              height: 100%;
              background-color: rgba(249, 247, 244, 0.8); }
            .site .page .hero > div .categories .col > div:before {
              left: calc(-200% - 4px); }
            .site .page .hero > div .categories .col > div:after {
              right: calc(-200% - 4px); }
          .site .page .hero > div .categories .col .parent {
            font-size: 23px;
            font-weight: 700;
            padding-bottom: 10px; }
          .site .page .hero > div .categories .col .list {
            max-width: 250px;
            margin: 0 auto; }
            .site .page .hero > div .categories .col .list ul {
              list-style: none;
              margin: 0;
              padding: 0;
              max-height: 185px;
              display: flex;
              flex-direction: column;
              flex-wrap: wrap;
              align-content: center;
              flex-basis: auto;
              flex-grow: 1; }
              .site .page .hero > div .categories .col .list ul li {
                margin: 2px 6px; }
                .site .page .hero > div .categories .col .list ul li button {
                  background-color: #fff;
                  font-size: 11px;
                  width: 100%;
                  text-align: left; }
                  .site .page .hero > div .categories .col .list ul li button > svg {
                    margin-right: 8px; }
                  .site .page .hero > div .categories .col .list ul li button:hover, .site .page .hero > div .categories .col .list ul li button.active {
                    background-color: #ff8400;
                    color: #fff; }
  .site.list .page .product-container {
    position: relative;
    margin-bottom: 20px; }
    .site.list .page .product-container > div {
      -webkit-transition: opacity 300ms;
      -moz-transition: opacity 300ms;
      -o-transition: opacity 300ms;
      transition: opacity 300ms;
      position: relative;
      height: 100%; }
  .site .proposalList {
    padding: 50px 30px; }
    .site .proposalList .backButton {
      font-size: 16px; }
      .site .proposalList .backButton a {
        color: #999; }
      .site .proposalList .backButton:hover {
        text-decoration: none !important; }
        .site .proposalList .backButton:hover a {
          color: #666; }
    .site .proposalList .alert {
      text-align: center; }
    .site .proposalList .table {
      background-color: #fff;
      font-size: 14px;
      /*overflow: hidden;
      border-radius: 6px;
      -moz-border-radius: 6px;
       -webkit-border-radius: 6px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 1px;
      -webkit-box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 1px;
      -moz-box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 1px;*/
      box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 1px;
      -webkit-box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 1px;
      -moz-box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 1px; }
      .site .proposalList .table thead {
        background-color: #f2f2f2; }
        .site .proposalList .table thead th {
          border-color: #ccc;
          font-weight: 500;
          border-bottom-width: 1px; }
          .site .proposalList .table thead th:not(:last-child) {
            border-right-color: #ddd;
            border-bottom-color: #ddd; }
          .site .proposalList .table thead th:last-child {
            width: 90px;
            text-align: center; }
      .site .proposalList .table tbody td {
        color: #555;
        border-color: #ccc;
        border-bottom-color: #e8e8e8; }
        .site .proposalList .table tbody td:not(:last-child) {
          border-right-color: #e8e8e8; }
        .site .proposalList .table tbody td button {
          color: #00afcc;
          padding: 0; }
          .site .proposalList .table tbody td button:hover {
            color: #00a6c2;
            text-decoration: none; }
        .site .proposalList .table tbody td .dropdown > button {
          color: #00afcc;
          cursor: pointer;
          padding: 0; }
          .site .proposalList .table tbody td .dropdown > button:hover {
            color: #00a6c2;
            text-decoration: none; }
        .site .proposalList .table tbody td .dropdown.show > button {
          color: #00a6c2;
          text-decoration: none; }
        .site .proposalList .table tbody td .dropdown .dropdown-menu .dropdown-item {
          font-size: 15px; }
          .site .proposalList .table tbody td .dropdown .dropdown-menu .dropdown-item.word:after, .site .proposalList .table tbody td .dropdown .dropdown-menu .dropdown-item.pdf:after {
            position: relative;
            margin-left: 10px;
            font: normal normal normal 16px/1 FontAwesome;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased; }
          .site .proposalList .table tbody td .dropdown .dropdown-menu .dropdown-item.word.word:after {
            content: "\f1c2"; }
          .site .proposalList .table tbody td .dropdown .dropdown-menu .dropdown-item.pdf.word:after {
            content: "\f1c2";
            content: "\f1c1"; }
          .site .proposalList .table tbody td .dropdown .dropdown-menu .dropdown-item.pdf.pdf:after {
            content: "\f1c1"; }
        .site .proposalList .table tbody td .quote {
          margin-left: 15px;
          color: #00afcc; }
          .site .proposalList .table tbody td .quote:hover {
            color: #00a6c2; }
        .site .proposalList .table tbody td > div {
          display: flex; }
        .site .proposalList .table tbody td:last-child {
          text-align: center; }
          .site .proposalList .table tbody td:last-child > div {
            display: block; }
        .site .proposalList .table tbody td .proposalBuilder {
          display: none; }
      .site .proposalList .table tbody tr:hover {
        background-color: #fafafa; }
      .site .proposalList .table tbody tr:last-child td {
        border-bottom-color: #ccc; }
  .site.detail .navbar {
    border-bottom: 1px solid #ccc; }
  .site.detail .hero {
    display: none; }

.detail.container .backButton {
  color: #999; }
  .detail.container .backButton:hover {
    text-decoration: none;
    color: #666; }

.detail.container .card {
  width: 100%;
  display: inline-block;
  border-color: #ccc; }
  .detail.container .card.has-products {
    width: calc(100% - 365px); }
  .detail.container .card .card-header .card-title {
    font-weight: 500;
    margin: 0;
    padding: 10px 0; }
  .detail.container .card .card-header .card-subtitle {
    color: #666; }
  .detail.container .card .card-header .cta {
    position: absolute;
    z-index: 1;
    top: -1px;
    right: -1px;
    width: auto;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
    /*@media (max-width: $breakpoint-small) {
              top: -61px;
              right: -15px;
              -webkit-transition: none;
              -moz-transition: none;
              -o-transition: none;
              transition: none;
            }*/ }
    .detail.container .card .card-header .cta .save, .detail.container .card .card-header .cta .preview, .detail.container .card .card-header .cta .add, .detail.container .card .card-header .cta .added {
      margin: 15px 15px 0 0;
      color: #fff;
      white-space: nowrap;
      text-align: center;
      background: #ff8400;
      text-decoration: none;
      border: 0;
      border-radius: 3px;
      font-size: 12px;
      padding: 5px 15px !important;
      font-weight: 300;
      -webkit-transition: background 200ms ease;
      -moz-transition: background 200ms ease;
      -o-transition: background 200ms ease;
      transition: background 200ms ease; }
    .detail.container .card .card-header .cta .save:hover, .detail.container .card .card-header .cta .preview:hover, .detail.container .card .card-header .cta .add:hover, .detail.container .card .card-header .cta .added:hover {
      background: #f57f00; }
    .detail.container .card .card-header .cta .save:focus, .detail.container .card .card-header .cta .preview:focus, .detail.container .card .card-header .cta .add:focus, .detail.container .card .card-header .cta .added:focus {
      outline: 0; }
    .detail.container .card .card-header .cta .save.added, .detail.container .card .card-header .cta .preview.added, .detail.container .card .card-header .cta .add.added, .detail.container .card .card-header .cta .added.added {
      background: #00afcc;
      position: relative; }
    .detail.container .card .card-header .cta .save.added:before, .detail.container .card .card-header .cta .preview.added:before, .detail.container .card .card-header .cta .add.added:before, .detail.container .card .card-header .cta .added.added:before {
      position: absolute;
      z-index: 1;
      top: -4px;
      left: -4px;
      content: "\f111";
      color: #fff;
      font: normal normal normal 14px/1 FontAwesome;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      text-shadow: 0.5px 0.5px 0 white; }
    .detail.container .card .card-header .cta .save.added:after, .detail.container .card .card-header .cta .preview.added:after, .detail.container .card .card-header .cta .add.added:after, .detail.container .card .card-header .cta .added.added:after {
      position: absolute;
      z-index: 2;
      top: -4px;
      left: -4px;
      content: "\f056";
      color: #000;
      font: normal normal normal 14px/1 FontAwesome;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      opacity: .75;
      -webkit-transition: opacity 200ms ease;
      -moz-transition: opacity 200ms ease;
      -o-transition: opacity 200ms ease;
      transition: opacity 200ms ease; }
    .detail.container .card .card-header .cta .save.added:hover, .detail.container .card .card-header .cta .preview.added:hover, .detail.container .card .card-header .cta .add.added:hover, .detail.container .card .card-header .cta .added.added:hover {
      background: #00a6c2; }
    .detail.container .card .card-header .cta .save.added:hover:after, .detail.container .card .card-header .cta .preview.added:hover:after, .detail.container .card .card-header .cta .add.added:hover:after, .detail.container .card .card-header .cta .added.added:hover:after {
      opacity: 1; }
  .detail.container .card .card-body .productDetailTabDisplay .tabList button:hover, .detail.container .card .card-body .productDetailTabDisplay .tabList button.active {
    color: #ff8400; }
  .detail.container .card .card-body .section .checkoption > div:hover {
    color: #00afcc; }
  .detail.container .card .card-body .section .checkoption.checked {
    color: #00afcc; }
    .detail.container .card .card-body .section .checkoption.checked input[type=checkbox]:after {
      color: #666; }

.sowBuilderSummary {
  position: relative;
  z-index: 10;
  border: 1px solid #d3d7dc;
  background-color: #fbfbfb;
  padding: 15px 0;
  box-shadow: rgba(0, 0, 0, 0.035) 0 1px 8px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.035) 0 1px 8px;
  -moz-box-shadow: rgba(0, 0, 0, 0.035) 0 1px 8px;
  vertical-align: top;
  margin-left: 25px;
  display: none;
  /*@media (max-width: $grid-breakpoints(lg)) {
    display:none;
  }*/ }
  .sowBuilderSummary > div {
    padding: 0 15px; }
  .sowBuilderSummary > .cta {
    position: absolute;
    padding: 0 15px;
    white-space: nowrap;
    margin-bottom: 14px; }
    .sowBuilderSummary > .cta .save, .sowBuilderSummary > .cta .preview, .sowBuilderSummary > .cta .add, .sowBuilderSummary > .cta .added {
      display: inline-block;
      color: #fff;
      white-space: nowrap;
      text-align: center;
      background: #ff8400;
      text-decoration: none;
      border: 0;
      border-radius: 3px;
      font-size: 12px;
      padding: 5px 15px !important;
      margin-right: 10px;
      font-weight: 300;
      -webkit-transition: background 200ms ease;
      -moz-transition: background 200ms ease;
      -o-transition: background 200ms ease;
      transition: background 200ms ease; }
    .sowBuilderSummary > .cta .save:last-child, .sowBuilderSummary > .cta .preview:last-child, .sowBuilderSummary > .cta .add:last-child, .sowBuilderSummary > .cta .added:last-child {
      margin-right: 0; }
    .sowBuilderSummary > .cta .save:hover, .sowBuilderSummary > .cta .preview:hover, .sowBuilderSummary > .cta .add:hover, .sowBuilderSummary > .cta .added:hover {
      background: #f57f00; }
    .sowBuilderSummary > .cta .save:focus, .sowBuilderSummary > .cta .preview:focus, .sowBuilderSummary > .cta .add:focus, .sowBuilderSummary > .cta .added:focus {
      outline: 0; }
    .sowBuilderSummary > .cta .save.submitting, .sowBuilderSummary > .cta .preview.submitting, .sowBuilderSummary > .cta .add.submitting, .sowBuilderSummary > .cta .added.submitting {
      pointer-events: none;
      opacity: .5;
      position: relative;
      padding-right: 35px; }
    .sowBuilderSummary > .cta .save.submitting:before, .sowBuilderSummary > .cta .preview.submitting:before, .sowBuilderSummary > .cta .add.submitting:before, .sowBuilderSummary > .cta .added.submitting:before {
      position: absolute;
      z-index: 2;
      top: 8px;
      right: 6px;
      content: "\f110";
      color: #fff;
      font: normal normal normal 11px/1 FontAwesome;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -webkit-transition: opacity 200ms ease;
      -moz-transition: opacity 200ms ease;
      -o-transition: opacity 200ms ease;
      transition: opacity 200ms ease;
      -webkit-animation: fa-spin 2s infinite linear;
      animation: fa-spin 2s infinite linear; }
    .sowBuilderSummary > .cta .save {
      width: 100%; }
    .sowBuilderSummary > .cta .save.submitting:after, .sowBuilderSummary > .cta .preview.submitting:after, .sowBuilderSummary > .cta .add.submitting:after, .sowBuilderSummary > .cta .added.submitting:after {
      display: none; }
    .sowBuilderSummary > .cta .save.added, .sowBuilderSummary > .cta .preview.added, .sowBuilderSummary > .cta .add.added, .sowBuilderSummary > .cta .added.added {
      background: green;
      position: relative; }
    .sowBuilderSummary > .cta .save.added:before, .sowBuilderSummary > .cta .preview.added:before, .sowBuilderSummary > .cta .add.added:before, .sowBuilderSummary > .cta .added.added:before {
      position: absolute;
      z-index: 1;
      top: -4px;
      left: -4px;
      content: "\f111";
      color: #fff;
      font: normal normal normal 14px/1 FontAwesome;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      text-shadow: 0.5px 0.5px 0 white; }
    .sowBuilderSummary > .cta .save.added:after, .sowBuilderSummary > .cta .preview.added:after, .sowBuilderSummary > .cta .add.added:after, .sowBuilderSummary > .cta .added.added:after {
      position: absolute;
      z-index: 2;
      top: -4px;
      left: -4px;
      content: "\f056";
      color: #000;
      font: normal normal normal 14px/1 FontAwesome;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      opacity: 0.75;
      -webkit-transition: opacity 200ms ease;
      -moz-transition: opacity 200ms ease;
      -o-transition: opacity 200ms ease;
      transition: opacity 200ms ease; }
    .sowBuilderSummary > .cta .save.added:hover, .sowBuilderSummary > .cta .preview.added:hover, .sowBuilderSummary > .cta .add.added:hover, .sowBuilderSummary > .cta .added.added:hover {
      background: #009300; }
    .sowBuilderSummary > .cta .save.added:hover:after, .sowBuilderSummary > .cta .preview.added:hover:after, .sowBuilderSummary > .cta .add.added:hover:after, .sowBuilderSummary > .cta .added.added:hover:after {
      opacity: 1; }
    .sowBuilderSummary > .cta .save.save, .sowBuilderSummary > .cta .preview.save, .sowBuilderSummary > .cta .add.save, .sowBuilderSummary > .cta .added.save {
      display: none;
      padding-right: 32px !important;
      position: relative; }
    .sowBuilderSummary > .cta .save.save .label, .sowBuilderSummary > .cta .preview.save .label, .sowBuilderSummary > .cta .add.save .label, .sowBuilderSummary > .cta .added.save .label {
      position: relative;
      top: 0;
      left: 0;
      line-height: 28px;
      height: 28px;
      padding: 0 29px 0 15px !important;
      background: #ff8400;
      border-radius: 3px;
      z-index: 2;
      -webkit-transition: all 400ms ease;
      -o-transition: all 400ms ease;
      transition: all 400ms ease; }
    .sowBuilderSummary > .cta .save.save:after, .sowBuilderSummary > .cta .preview.save:after, .sowBuilderSummary > .cta .add.save:after, .sowBuilderSummary > .cta .added.save:after {
      position: absolute;
      border: 0;
      z-index: 2;
      top: 1px;
      right: 20px;
      content: "\f019";
      color: #fff;
      font: normal normal normal 14px/1 FontAwesome;
      line-height: 25px;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      -webkit-transition: transform 400ms ease;
      -moz-transition: transform 400ms ease;
      -o-transition: transform 400ms ease;
      transition: transform 400ms ease;
      transition-delay: 400ms; }
    .sowBuilderSummary > .cta .save.save .docTypes, .sowBuilderSummary > .cta .preview.save .docTypes, .sowBuilderSummary > .cta .add.save .docTypes, .sowBuilderSummary > .cta .added.save .docTypes {
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      opacity: 0;
      background: #ff8400;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      border-left: 1px solid rgba(0, 0, 0, 0.2);
      height: 28px;
      padding: 0 8px;
      -webkit-box-shadow: inset 13px 0px 5px -10px rgba(0, 0, 0, 0.075);
      -moz-box-shadow: inset 13px 0px 5px -10px rgba(0, 0, 0, 0.075);
      box-shadow: inset 13px 0px 5px -10px rgba(0, 0, 0, 0.075);
      -webkit-font-smoothing: antialiased;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      -webkit-transition: all 400ms ease;
      -moz-transition: all 400ms ease;
      -o-transition: all 400ms ease;
      transition: all 400ms ease;
      transition-delay: 100ms; }
    .sowBuilderSummary > .cta .save.save .docTypes i, .sowBuilderSummary > .cta .preview.save .docTypes i, .sowBuilderSummary > .cta .add.save .docTypes i, .sowBuilderSummary > .cta .added.save .docTypes i {
      display: inline-block;
      width: 30px;
      height: 28px;
      position: relative;
      text-align: center; }
    .sowBuilderSummary > .cta .save.save .docTypes i:after, .sowBuilderSummary > .cta .preview.save .docTypes i:after, .sowBuilderSummary > .cta .add.save .docTypes i:after, .sowBuilderSummary > .cta .added.save .docTypes i:after {
      position: absolute;
      z-index: 3;
      width: 30px;
      height: 28px;
      top: -1px;
      left: 50%;
      transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -webkit-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      border: 0;
      content: "\f1c1";
      font: normal normal normal 16px/1 FontAwesome;
      line-height: 28px;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      color: #fff; }
    .sowBuilderSummary > .cta .save.save .docTypes i.word:after, .sowBuilderSummary > .cta .preview.save .docTypes i.word:after, .sowBuilderSummary > .cta .add.save .docTypes i.word:after, .sowBuilderSummary > .cta .added.save .docTypes i.word:after {
      content: "\f1c2";
      opacity: 0.5; }
    .sowBuilderSummary > .cta .save.save .docTypes i.pdf:after, .sowBuilderSummary > .cta .preview.save .docTypes i.pdf:after, .sowBuilderSummary > .cta .add.save .docTypes i.pdf:after, .sowBuilderSummary > .cta .added.save .docTypes i.pdf:after {
      content: "\f1c1";
      opacity: 0.5; }
    .sowBuilderSummary > .cta .save.save .docTypes i:hover:after, .sowBuilderSummary > .cta .save.save .docTypes i.active:after {
      opacity: 1 !important; }
    .sowBuilderSummary > .cta .preview.save .docTypes i:hover:after, .sowBuilderSummary > .cta .preview.save .docTypes i.active:after {
      opacity: 1 !important; }
    .sowBuilderSummary > .cta .add.save .docTypes i:hover:after, .sowBuilderSummary > .cta .add.save .docTypes i.active:after {
      opacity: 1 !important; }
    .sowBuilderSummary > .cta .added.save .docTypes i:hover:after, .sowBuilderSummary > .cta .added.save .docTypes i.active:after {
      opacity: 1 !important; }
    .sowBuilderSummary > .cta .save.save:hover .label, .sowBuilderSummary > .cta .preview.save:hover .label, .sowBuilderSummary > .cta .add.save:hover .label, .sowBuilderSummary > .cta .added.save:hover .label {
      background: #f57f00;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .sowBuilderSummary > .cta .save.save:hover .docTypes, .sowBuilderSummary > .cta .preview.save:hover .docTypes, .sowBuilderSummary > .cta .add.save:hover .docTypes, .sowBuilderSummary > .cta .added.save:hover .docTypes {
      transform: translateX(100%);
      -moz-transform: translateX(100%);
      -webkit-transform: translateX(100%);
      -o-transform: translateX(100%);
      width: auto;
      opacity: 1; }
    .sowBuilderSummary > .cta .save.save:hover + .preview, .sowBuilderSummary > .cta .preview.save:hover + .preview, .sowBuilderSummary > .cta .add.save:hover + .preview, .sowBuilderSummary > .cta .added.save:hover + .preview {
      opacity: 0; }
    .sowBuilderSummary > .cta .save.save:focus, .sowBuilderSummary > .cta .preview.save:focus, .sowBuilderSummary > .cta .add.save:focus, .sowBuilderSummary > .cta .added.save:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.25);
      -webkit-box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.25);
      -moz-box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.25); }
    .sowBuilderSummary > .cta .save.save + .dropdown-menu .dropdown-item, .sowBuilderSummary > .cta .preview.save + .dropdown-menu .dropdown-item, .sowBuilderSummary > .cta .add.save + .dropdown-menu .dropdown-item, .sowBuilderSummary > .cta .added.save + .dropdown-menu .dropdown-item {
      font-size: 12px; }
    .sowBuilderSummary > .cta .save.save + .dropdown-menu .dropdown-item.word:after, .sowBuilderSummary > .cta .save.save + .dropdown-menu .dropdown-item.pdf:after {
      position: relative;
      margin-left: 10px;
      font: normal normal normal 14px/1 FontAwesome;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased; }
    .sowBuilderSummary > .cta .preview.save + .dropdown-menu .dropdown-item.word:after, .sowBuilderSummary > .cta .preview.save + .dropdown-menu .dropdown-item.pdf:after {
      position: relative;
      margin-left: 10px;
      font: normal normal normal 14px/1 FontAwesome;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased; }
    .sowBuilderSummary > .cta .add.save + .dropdown-menu .dropdown-item.word:after, .sowBuilderSummary > .cta .add.save + .dropdown-menu .dropdown-item.pdf:after {
      position: relative;
      margin-left: 10px;
      font: normal normal normal 14px/1 FontAwesome;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased; }
    .sowBuilderSummary > .cta .added.save + .dropdown-menu .dropdown-item.word:after, .sowBuilderSummary > .cta .added.save + .dropdown-menu .dropdown-item.pdf:after {
      position: relative;
      margin-left: 10px;
      font: normal normal normal 14px/1 FontAwesome;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased; }
    .sowBuilderSummary > .cta .save.save + .dropdown-menu .dropdown-item.word.word:after, .sowBuilderSummary > .cta .save.save + .dropdown-menu .dropdown-item.pdf.word:after {
      content: "\f1c2"; }
    .sowBuilderSummary > .cta .preview.save + .dropdown-menu .dropdown-item.word.word:after, .sowBuilderSummary > .cta .preview.save + .dropdown-menu .dropdown-item.pdf.word:after {
      content: "\f1c2"; }
    .sowBuilderSummary > .cta .add.save + .dropdown-menu .dropdown-item.word.word:after, .sowBuilderSummary > .cta .add.save + .dropdown-menu .dropdown-item.pdf.word:after {
      content: "\f1c2"; }
    .sowBuilderSummary > .cta .added.save + .dropdown-menu .dropdown-item.word.word:after, .sowBuilderSummary > .cta .added.save + .dropdown-menu .dropdown-item.pdf.word:after {
      content: "\f1c2"; }
    .sowBuilderSummary > .cta .save.save + .dropdown-menu .dropdown-item.word.pdf:after, .sowBuilderSummary > .cta .save.save + .dropdown-menu .dropdown-item.pdf.pdf:after {
      content: "\f1c1"; }
    .sowBuilderSummary > .cta .preview.save + .dropdown-menu .dropdown-item.word.pdf:after, .sowBuilderSummary > .cta .preview.save + .dropdown-menu .dropdown-item.pdf.pdf:after {
      content: "\f1c1"; }
    .sowBuilderSummary > .cta .add.save + .dropdown-menu .dropdown-item.word.pdf:after, .sowBuilderSummary > .cta .add.save + .dropdown-menu .dropdown-item.pdf.pdf:after {
      content: "\f1c1"; }
    .sowBuilderSummary > .cta .added.save + .dropdown-menu .dropdown-item.word.pdf:after, .sowBuilderSummary > .cta .added.save + .dropdown-menu .dropdown-item.pdf.pdf:after {
      content: "\f1c1"; }
    .sowBuilderSummary > .cta .save.preview, .sowBuilderSummary > .cta .preview.preview, .sowBuilderSummary > .cta .add.preview, .sowBuilderSummary > .cta .added.preview {
      display: none;
      padding-right: 23px !important;
      position: relative;
      opacity: 1;
      -webkit-transition: opacity 400ms ease;
      -o-transition: opacity 400ms ease;
      transition: opacity 400ms ease; }
    .sowBuilderSummary > .cta .save.preview:after, .sowBuilderSummary > .cta .preview.preview:after, .sowBuilderSummary > .cta .add.preview:after, .sowBuilderSummary > .cta .added.preview:after {
      position: absolute;
      z-index: 2;
      top: -1px;
      right: 8px;
      content: "\f107";
      color: #fff;
      font: normal normal normal 14px/1 FontAwesome;
      line-height: 25px;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      -webkit-transition: transform 400ms ease;
      -moz-transition: transform 400ms ease;
      -o-transition: transform 400ms ease;
      transition: transform 400ms ease;
      transition-delay: 400ms; }
  .sowBuilderSummary .summary {
    /*text-align: right;*/ }
    .sowBuilderSummary .summary > div {
      font-size: 12px;
      line-height: 15px;
      font-weight: normal;
      color: #888;
      white-space: nowrap; }
      .sowBuilderSummary .summary > div span {
        font-weight: bold;
        color: #333; }
    .sowBuilderSummary .summary .variables {
      white-space: nowrap;
      /* margin-bottom: 25px; */ }
      .sowBuilderSummary .summary .variables > input {
        display: block;
        border: 0;
        border-bottom: 1px solid #e4e4e4;
        color: #555;
        font-size: 12px;
        line-height: 20px;
        background-color: transparent;
        width: 100%;
        /*&::-webkit-input-placeholder {
          color: $sowbuilder-variable-color !important;
        }
        &:-ms-input-placeholder {
          color: $sowbuilder-variable-color !important;
        }
        &::-moz-placeholder {
          color: $sowbuilder-variable-color !important;
        }*/ }
        .sowBuilderSummary .summary .variables > input:focus {
          outline: 0; }
          .sowBuilderSummary .summary .variables > input:focus::-webkit-input-placeholder, .sowBuilderSummary .summary .variables > input:focus:-ms-input-placeholder, .sowBuilderSummary .summary .variables > input:focus::-moz-placeholder {
            color: #555 !important; }
        .sowBuilderSummary .summary .variables > input + input {
          margin-top: 6px; }
      .sowBuilderSummary .summary .variables .invalid {
        border-bottom: red solid 1px; }
    .sowBuilderSummary .summary .total {
      margin-top: 20px;
      padding: 25px 0 0 0; }
      .sowBuilderSummary .summary .total + .total {
        padding: 0; }
        .sowBuilderSummary .summary .total + .total span {
          padding-left: 8px; }
  .sowBuilderSummary > .preview {
    border-top: 1px solid #ddd;
    margin-top: 20px !important;
    padding: 20px 20px 20px 27px !important;
    max-height: 400px;
    overflow-y: scroll;
    text-align: left;
    white-space: normal; }
    .sowBuilderSummary > .preview::-webkit-scrollbar {
      width: 14px; }
    .sowBuilderSummary > .preview::-webkit-scrollbar-track {
      position: absolute;
      background: #fafafa;
      border-left: 1px solid #e7e7e7; }
    .sowBuilderSummary > .preview::-webkit-scrollbar-thumb {
      position: absolute;
      background-color: #c1c1c1;
      border: 3px solid transparent;
      border-left-width: 4px;
      border-radius: 9px;
      background-clip: content-box;
      -webkit-transition: background-color 200ms ease;
      -moz-transition: background-color 200ms ease;
      -o-transition: background-color 200ms ease;
      transition: background-color 200ms ease; }
      .sowBuilderSummary > .preview::-webkit-scrollbar-thumb:hover, .sowBuilderSummary > .preview::-webkit-scrollbar-thumb:active {
        position: absolute;
        background-color: #7d7d7d; }
    .sowBuilderSummary > .preview > div {
      border-top: 1px solid #ddd;
      margin-top: 30px;
      padding-top: 25px; }
      .sowBuilderSummary > .preview > div:first-child {
        border-top: 0;
        margin: 0;
        padding: 0; }
    .sowBuilderSummary > .preview h5 {
      font-size: 14px;
      line-height: normal;
      font-weight: 400;
      margin: 0 5px 5px 0;
      color: #00afcc;
      position: relative; }
      .sowBuilderSummary > .preview h5 .remove:after {
        position: absolute;
        z-index: 2;
        top: 2px;
        left: -16px;
        content: "\f056";
        color: #333;
        font: normal normal normal 14px/1 FontAwesome;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        opacity: 0.75;
        -webkit-transition: opacity 200ms ease;
        -moz-transition: opacity 200ms ease;
        -o-transition: opacity 200ms ease;
        transition: opacity 200ms ease; }
      .sowBuilderSummary > .preview h5 .remove:hover {
        cursor: pointer; }
        .sowBuilderSummary > .preview h5 .remove:hover:before {
          color: #888; }
        .sowBuilderSummary > .preview h5 .remove:hover:after {
          opacity: 1; }
    .sowBuilderSummary > .preview .priceTime {
      margin-top: 0;
      font-size: 13px;
      line-height: 13px;
      margin-bottom: 0; }
      .sowBuilderSummary > .preview .priceTime span {
        color: #455260 !important;
        padding: 0 !important; }
        .sowBuilderSummary > .preview .priceTime span + span {
          /*border-left: 1px solid #ddd;
            margin-left: 7px !important;
            padding-left: 7px !important;*/ }
    .sowBuilderSummary > .preview p {
      margin-top: 10px;
      margin-bottom: 0;
      font-size: 10px;
      line-height: 14px;
      font-weight: normal !important; }
      .sowBuilderSummary > .preview p h2 {
        font-size: 10px; }
      .sowBuilderSummary > .preview p strong {
        font-weight: normal !important; }
    .sowBuilderSummary > .preview .productRow {
      margin-top: 20px; }
      .sowBuilderSummary > .preview .productRow label {
        font-size: 14px;
        line-height: normal;
        font-weight: 400;
        font-style: italic;
        margin: 0;
        padding-bottom: 5px;
        color: #666;
        display: block;
        width: 100%;
        border-bottom: 1px solid #eee; }
      .sowBuilderSummary > .preview .productRow .option {
        margin: 15px 0 0 20px; }
        .sowBuilderSummary > .preview .productRow .option ul {
          padding-left: 25px;
          margin-bottom: 10px; }
        .sowBuilderSummary > .preview .productRow .option:last-child {
          margin-bottom: 0; }
  .sowBuilderSummary > .download {
    visibility: hidden;
    height: 0;
    padding: 0;
    text-align: left; }
    .sowBuilderSummary > .download > * {
      visibility: hidden;
      height: 0; }
  .sowBuilderSummary.has-products {
    width: 325px;
    display: inline-block; }
    .sowBuilderSummary.has-products .cta {
      width: 100%; }
      .sowBuilderSummary.has-products .cta .save, .sowBuilderSummary.has-products .cta .preview {
        margin-top: 45px;
        display: inline-block; }
      .sowBuilderSummary.has-products .cta .save.preview, .sowBuilderSummary.has-products .cta .preview.preview {
        display: none; }
    .sowBuilderSummary.has-products.preview-showing {
      -webkit-transition: all 200ms;
      -moz-transition: all 200ms;
      -o-transition: all 200ms;
      transition: all 200ms;
      width: 325px;
      padding-bottom: 0; }
      .sowBuilderSummary.has-products.preview-showing .cta .preview:after {
        -webkit-font-smoothing: antialiased;
        -ms-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg); }
      .sowBuilderSummary.has-products.preview-showing > .preview {
        display: block;
        background-color: #fff;
        padding-bottom: 20px; }

@media (max-width: 1400px) {
  .sowBuilderSummary > .cta .save.save:hover .label, .sowBuilderSummary > .cta .preview.save:hover .label, .sowBuilderSummary > .cta .add.save:hover .label, .sowBuilderSummary > .cta .added.save:hover .label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px; }
  .sowBuilderSummary > .cta .save.save:hover .docTypes, .sowBuilderSummary > .cta .preview.save:hover .docTypes, .sowBuilderSummary > .cta .add.save:hover .docTypes, .sowBuilderSummary > .cta .added.save:hover .docTypes {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-left: 0;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    left: 0;
    right: auto;
    transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    -webkit-box-shadow: inset -13px 0px 5px -10px rgba(0, 0, 0, 0.075);
    -moz-box-shadow: inset -13px 0px 5px -10px rgba(0, 0, 0, 0.075);
    box-shadow: inset -13px 0px 5px -10px rgba(0, 0, 0, 0.075); } }

.proposalBuilder {
  /*> .container {
    padding-bottom:75px;
    margin-bottom:0;

    &:last-child {
      padding-bottom:140px;
    }
  }

  h3 {
    margin: 0 auto 25px;
    text-align: center;
    color: $color-blue;
    font-size: 33px;
    font-weight:600;
  }


  .card {
    margin-bottom: 20px;
    border-radius: 0;

    &:hover {
      cursor: pointer;
      border-color: #cacdd0;
      background-color: #fcfcfc;
      box-shadow: 0 1px 8px rgba(0,0,0,.1);
      -webkit-box-shadow: rgba(0,0,0,.1) 0 1px 8px;
      -moz-box-shadow: rgba(0,0,0,.1) 0 1px 8px;
    }

    .card-header {
      background: none;
      border-bottom: 0;
      padding-bottom: 0;

      .card-title {
        font-size: 17px;
        margin: 30px 0px;
        font-weight: 600;
      }
    }
    .card-body {
      padding-top: 0;
      padding-bottom: 0;

      .card-text {
        font-size: 12px;
      }
    }
  }

  .product-container {
    position: relative;
    margin-bottom: 20px;
    text-align: center;

    > div {
      -webkit-transition: opacity 300ms;
      -moz-transition: opacity 300ms;
      -o-transition: opacity 300ms;
      transition: opacity 300ms;
      position: relative;
      height: 100%;
      border:1px solid #ddd;

      .card {
        border: 0;
        min-height: 100%;

        .card-header {
          .card-title {
            margin: 0;
            color:#555;
          }

          .card-subtitle {
            margin: 10px 0 20px 0;
            .priceTime {
              font-size:17px;
              color:#888;
            }
          }
        }
      }

      &.active {
        border-color:#bbb !important;
        .card {
          background-color:#fff !important;
        }
      }

      .sowBuilder {
        position: absolute;
        top: -9px;
        right: -9px;
        opacity: 0;
        pointer-events: none;
        -webkit-transition: opacity 200ms ease;
        -moz-transition: opacity 200ms ease;
        -o-transition: opacity 200ms ease;
        transition: opacity 200ms ease;
        z-index: 2;

        &.active {
          opacity: 1;
          pointer-events: auto;
        }

        .fa-check-circle {
          color: #888;
          font-size: 24px;
          position:relative;
          top:0;
          right:0;
          z-index:2;
        }
        .fa-circle {
          color: #fff;
          font-size: 24px;
          position:absolute;
          top:0;
          right:0;
          z-index:1;
        }
        !*button {
          position: relative;
          display: inline-block;
          color: #fff;
          white-space: nowrap;
          text-align: center;
          background: green;
          text-decoration: none;
          border-color: rgba(255, 255, 255, 0.75);
          border-radius: 3px;
          font-size: 11px;
          padding: 5px 12px 5px 10px !important;
          font-weight: 300;
          -webkit-transition: background 200ms ease;
          -moz-transition: background 200ms ease;
          -o-transition: background 200ms ease;
          transition: background 200ms ease;

          &:focus {
            outline: 0;
          }
        }*!
      }
    }
  }

  .items {
    justify-content: center;
    .product-container {
      > div {
        .card {
          overflow: hidden;
          background-color: #fff;
          padding:15px;

          &:hover {
            border-color: #b4b7ba;
            background-color: #fdfdfd;
          }

        }
      }

      &.col-lg-12 {
        > div {
          &:before,
          &:after {
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            -o-transform: translateY(-50%);
          }

          .card {
            //min-height: 100%;
            &:before {
              border-width: 400px 0 0 210px;
            }

            .card-body {
              .card-text {
                font-size: 12px;
              }
            }
          }

          &:hover {
            height: 100%;
            //padding-bottom:20px;

            .card {
              min-height: 100%;
            }
          }
        }
      }
    }
  }

  .review {
    table {
      background-color: #fff;
      border:1px solid #ddd;
      max-width:750px;
      margin: 0 auto;

      td, th {
        border-bottom-color:#eee;
        &:first-child {
          border-right-color:#eee;
          width:80%;
        }
        &:last-child {
          border-right:0;
        }
      }

      thead {
        td, th {
          border-bottom-width: 1px;
          padding-top:22px;
          padding-bottom:35px;
          text-align: center;
        }
        .variables {
          white-space: nowrap;
          margin: 0 auto;
          text-align: center;
          display:inline-block;

          > input {
            display: block;
            border: 0;
            border-bottom: 1px dotted #eee;
            width: 375px;
            padding:5px;
            color: #666;
            text-align: center;

            &::-webkit-input-placeholder { color: #999 !important; }
            &:-ms-input-placeholder { color: #999 !important; }
            &::-moz-placeholder { color: #999 !important; }
            &:focus::-webkit-input-placeholder { color: #666 !important; }
            &:focus:-ms-input-placeholder { color: #666 !important; }
            &:focus::-moz-placeholder { color: #666 !important; }

            + input {
              margin-top:12px;
            }

            &:focus {
              outline:0;
              border-bottom-color: #ddd;
            }

            &.error {
              font-style:normal;
              border-bottom-color: red;

              &::-webkit-input-placeholder { color: red !important; }
              &:-ms-input-placeholder { color: red !important; }
              &::-moz-placeholder { color: red !important; }
              &:focus::-webkit-input-placeholder { color: red !important; }
              &:focus:-ms-input-placeholder { color: red !important; }
              &:focus::-moz-placeholder { color: red !important; }
            }
          }
        }
      }

      tbody {
        td, th {
          color:#666;

          .priceTime {
            margin: 0;
            line-height: initial;
          }
        }
      }

      tfoot {
        td, th {
          color:#333;
          font-weight:bold;
          border-bottom:0;
        }
      }
    }

    > .dropdown {
      margin: 0 auto;
      > button {
        margin:25px auto 0;
        text-align: center;
        background-color:$button-color;
        border:0;

        &:hover {
          background-color:$button-color-hover;
        }

        &:focus {
          box-shadow:none;
        }


      }

      > .dropdown-menu {
        width: 100%;

        .dropdown-item {
          &.word, &.pdf {
            &:after {
              position: relative;
              margin-left: 10px;
              font: normal normal normal 16px/1 FontAwesome;
              text-rendering: auto;
              -webkit-font-smoothing: antialiased;
            }
            &.word {
              &:after {
                content: "\f1c2";
              }
            }
            &.pdf {
              &:after {
                content: "\f1c1";
              }
            }
          }
        }
      }
    }

    button {
      &.submitting {
        pointer-events: none;
        opacity: .5;
        position: relative;
        padding-right:35px;

        &:before {
          position: absolute;
          z-index: 2;
          top: 10px;
          right: 12px;
          content: "\f110";
          color: #fff;
          font: normal normal normal 14px/1 FontAwesome;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -webkit-transition: opacity 200ms ease;
          -moz-transition: opacity 200ms ease;
          -o-transition: opacity 200ms ease;
          transition: opacity 200ms ease;
          -webkit-animation: fa-spin 2s infinite linear;
          animation: fa-spin 2s infinite linear;
        }
        &:after {
          display:none;
        }
      }
    }
  }*/ }
  .proposalBuilder .options {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    margin: 25px 0 25px 0;
    position: relative; }
    .proposalBuilder .options > .search {
      width: 27%;
      margin-right: 20px;
      border-radius: 0;
      position: relative; }
      .proposalBuilder .options > .search .form-control {
        border-radius: 0; }
      .proposalBuilder .options > .search .fa-times {
        position: absolute;
        top: 10px;
        right: 10px;
        color: #ccc;
        -webkit-transition: color 200ms;
        -moz-transition: color 200ms;
        -o-transition: color 200ms;
        transition: color 200ms; }
        .proposalBuilder .options > .search .fa-times:hover {
          color: #999;
          cursor: pointer; }
    .proposalBuilder .options > .categories {
      width: 27%;
      margin-right: 20px;
      /*.categories__placeholder {
          color:$input-placeholder-color;
        }*/ }
      .proposalBuilder .options > .categories .categories__control {
        border-radius: 0;
        padding: 0;
        font-size: 14px;
        line-height: 21px;
        min-height: 33px;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; }
        .proposalBuilder .options > .categories .categories__control .categories__value-container {
          padding: 0 10px;
          flex-wrap: nowrap; }
          .proposalBuilder .options > .categories .categories__control .categories__value-container .categories__placeholder {
            color: #999; }
      .proposalBuilder .options > .categories .categories__control--is-focused {
        background-color: #fff;
        border-color: #80bdff;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
      .proposalBuilder .options > .categories .categories__indicator {
        padding: 0 8px; }
      .proposalBuilder .options > .categories .categories__indicator-separator {
        display: none; }
      .proposalBuilder .options > .categories .categories__option {
        padding: 4px 12px; }
      .proposalBuilder .options > .categories .categories__option--is-focused {
        background-color: #f1f1f1; }
      .proposalBuilder .options > .categories .categories__option--is-selected {
        background-color: #fff;
        color: #bbb; }
      .proposalBuilder .options > .categories .categories__multi-value {
        background-color: #e9e9e9; }
      .proposalBuilder .options > .categories .categories__multi-value__label {
        padding: 0px 1px 0px 6px; }
    .proposalBuilder .options > .listGridToggle {
      white-space: nowrap;
      margin-right: 50px; }
      .proposalBuilder .options > .listGridToggle button {
        border-radius: 0; }
        .proposalBuilder .options > .listGridToggle button + button {
          margin-left: 5px; }
        .proposalBuilder .options > .listGridToggle button:not(.active) {
          opacity: 0.2; }
        .proposalBuilder .options > .listGridToggle button.active {
          opacity: 0.4; }
    .proposalBuilder .options > .price {
      width: 200px;
      margin-top: 25px; }
      .proposalBuilder .options > .price .input-range {
        height: auto;
        top: -1px; }
        .proposalBuilder .options > .price .input-range .input-range__track--active {
          background: #e2e2e2 !important;
          height: 1px; }
        .proposalBuilder .options > .price .input-range:before {
          display: none;
          position: absolute;
          content: "";
          bottom: -18px;
          left: calc(50% + 2px);
          width: 1px;
          height: 6px;
          background-color: #ddd;
          bottom: -17px; }
        .proposalBuilder .options > .price .input-range .input-range__track--background {
          height: 1px !important; }
          .proposalBuilder .options > .price .input-range .input-range__track--background:before {
            display: none;
            position: absolute;
            content: "";
            bottom: -18px;
            left: calc(50% + 2px);
            width: 1px;
            height: 6px;
            background-color: #ddd;
            left: 2px; }
          .proposalBuilder .options > .price .input-range .input-range__track--background:after {
            display: none;
            position: absolute;
            content: "";
            bottom: -18px;
            left: calc(50% + 2px);
            width: 1px;
            height: 6px;
            background-color: #ddd;
            left: auto;
            right: -8px; }
        .proposalBuilder .options > .price .input-range .input-range__slider {
          background: #f5f5f5 !important;
          border: 1px solid #e2e2e2 !important;
          border-radius: 0 !important;
          width: 15px !important;
          height: 15px !important;
          margin-top: -8px !important; }
          .proposalBuilder .options > .price .input-range .input-range__slider:hover {
            border: 1px solid #ccc !important; }
          .proposalBuilder .options > .price .input-range .input-range__slider:active {
            background: #fcfcfc !important;
            border: 1px solid #ccc !important;
            box-shadow: rgba(0, 0, 0, 0.025) 0px 1px 6px, rgba(0, 0, 0, 0.025) 0px 1px 4px !important; }
        .proposalBuilder .options > .price .input-range .input-range__label--min, .proposalBuilder .options > .price .input-range .input-range__label--max {
          display: none; }
        .proposalBuilder .options > .price .input-range .input-range__label--value {
          top: -28px;
          left: -1.5px;
          font-size: 11.5px;
          color: #999; }
        .proposalBuilder .options > .price .input-range .input-range__slider-container .input-range__label--value {
          margin-left: -3px; }
        .proposalBuilder .options > .price .input-range .input-range__slider-container .input-range__slider {
          margin-left: -10px;
          margin-right: 0; }
        .proposalBuilder .options > .price .input-range .input-range__slider-container + .input-range__slider-container .input-range__label--value {
          margin-left: 8px; }
        .proposalBuilder .options > .price .input-range .input-range__slider-container + .input-range__slider-container .input-range__slider {
          margin-left: 0;
          margin-right: -10px; }
      .proposalBuilder .options > .price.filtered .input-range__track--active {
        background: #ccc !important; }
      .proposalBuilder .options > .price.filtered .input-range__label--value {
        color: #666; }
      .proposalBuilder .options > .price.filtered .input-range__slider {
        background: #fcfcfc !important;
        border: 1px solid #ccc !important; }
    .proposalBuilder .options .sowBuilderSummary {
      position: absolute;
      top: -34px;
      right: -1px;
      width: 325px;
      padding: 0 0 10px 0;
      box-sizing: border-box; }
      .proposalBuilder .options .sowBuilderSummary > div {
        padding: 0 12px; }
      .proposalBuilder .options .sowBuilderSummary .summary > div {
        line-height: 12px;
        font-size: 10px; }
      .proposalBuilder .options .sowBuilderSummary .summary .variables > input {
        font-size: 10px;
        line-height: 18px;
        display: inline-block; }
        .proposalBuilder .options .sowBuilderSummary .summary .variables > input:first-child {
          width: 125px; }
        .proposalBuilder .options .sowBuilderSummary .summary .variables > input:last-child {
          width: 60px;
          margin-left: 5px; }
      .proposalBuilder .options .sowBuilderSummary .summary .total {
        padding: 15px 0 0 0; }
        .proposalBuilder .options .sowBuilderSummary .summary .total + .total {
          padding: 0; }
      .proposalBuilder .options .sowBuilderSummary > .cta {
        display: none;
        position: absolute;
        top: 7.5px;
        right: 0; }
        .proposalBuilder .options .sowBuilderSummary > .cta .save, .proposalBuilder .options .sowBuilderSummary > .cta .preview, .proposalBuilder .options .sowBuilderSummary > .cta .add, .proposalBuilder .options .sowBuilderSummary > .cta .added {
          display: block;
          width: 85px;
          padding: 0 10px !important;
          margin-right: 0;
          text-align: left;
          box-sizing: border-box;
          font-size: 10px;
          line-height: 20px; }
        .proposalBuilder .options .sowBuilderSummary > .cta .save.submitting:before, .proposalBuilder .options .sowBuilderSummary > .cta .preview.submitting:before, .proposalBuilder .options .sowBuilderSummary > .cta .add.submitting:before, .proposalBuilder .options .sowBuilderSummary > .cta .added.submitting:before {
          top: 5px; }
        .proposalBuilder .options .sowBuilderSummary > .cta .save.preview, .proposalBuilder .options .sowBuilderSummary > .cta .preview.preview, .proposalBuilder .options .sowBuilderSummary > .cta .add.preview, .proposalBuilder .options .sowBuilderSummary > .cta .added.preview {
          margin-top: 2px; }
        .proposalBuilder .options .sowBuilderSummary > .cta .save.add, .proposalBuilder .options .sowBuilderSummary > .cta .save.added {
          display: none; }
        .proposalBuilder .options .sowBuilderSummary > .cta .preview.add, .proposalBuilder .options .sowBuilderSummary > .cta .preview.added {
          display: none; }
        .proposalBuilder .options .sowBuilderSummary > .cta .add.add, .proposalBuilder .options .sowBuilderSummary > .cta .add.added {
          display: none; }
        .proposalBuilder .options .sowBuilderSummary > .cta .added.added {
          display: none; }
        .proposalBuilder .options .sowBuilderSummary > .cta .save.save, .proposalBuilder .options .sowBuilderSummary > .cta .preview.save, .proposalBuilder .options .sowBuilderSummary > .cta .add.save, .proposalBuilder .options .sowBuilderSummary > .cta .added.save {
          height: 20px;
          padding-right: 23px !important;
          /*&:after {
              top: -3px;
              right: 16px;
              //font-size: 11px;
            }*/ }
        .proposalBuilder .options .sowBuilderSummary > .cta .save.save .label, .proposalBuilder .options .sowBuilderSummary > .cta .preview.save .label, .proposalBuilder .options .sowBuilderSummary > .cta .add.save .label, .proposalBuilder .options .sowBuilderSummary > .cta .added.save .label {
          line-height: 20px;
          height: 20px;
          padding: 0 29px 0 10px !important; }
        .proposalBuilder .options .sowBuilderSummary > .cta .save.save:after, .proposalBuilder .options .sowBuilderSummary > .cta .preview.save:after, .proposalBuilder .options .sowBuilderSummary > .cta .add.save:after, .proposalBuilder .options .sowBuilderSummary > .cta .added.save:after {
          top: -1px;
          right: 20px; }
        .proposalBuilder .options .sowBuilderSummary > .cta .save.save .docTypes, .proposalBuilder .options .sowBuilderSummary > .cta .preview.save .docTypes, .proposalBuilder .options .sowBuilderSummary > .cta .add.save .docTypes, .proposalBuilder .options .sowBuilderSummary > .cta .added.save .docTypes, .proposalBuilder .options .sowBuilderSummary > .cta .save.save .docTypes i, .proposalBuilder .options .sowBuilderSummary > .cta .preview.save .docTypes i, .proposalBuilder .options .sowBuilderSummary > .cta .add.save .docTypes i, .proposalBuilder .options .sowBuilderSummary > .cta .added.save .docTypes i {
          height: 20px; }
        .proposalBuilder .options .sowBuilderSummary > .cta .save.save .docTypes i:after, .proposalBuilder .options .sowBuilderSummary > .cta .preview.save .docTypes i:after, .proposalBuilder .options .sowBuilderSummary > .cta .add.save .docTypes i:after, .proposalBuilder .options .sowBuilderSummary > .cta .added.save .docTypes i:after {
          top: 0;
          height: 20px;
          line-height: 20px;
          font-size: 14px; }
        .proposalBuilder .options .sowBuilderSummary > .cta .save.save:hover + .preview, .proposalBuilder .options .sowBuilderSummary > .cta .preview.save:hover + .preview, .proposalBuilder .options .sowBuilderSummary > .cta .add.save:hover + .preview, .proposalBuilder .options .sowBuilderSummary > .cta .added.save:hover + .preview {
          opacity: 1; }
        .proposalBuilder .options .sowBuilderSummary > .cta .save.preview:after, .proposalBuilder .options .sowBuilderSummary > .cta .preview.preview:after, .proposalBuilder .options .sowBuilderSummary > .cta .add.preview:after, .proposalBuilder .options .sowBuilderSummary > .cta .added.preview:after {
          top: -3px; }
      .proposalBuilder .options .sowBuilderSummary > .preview {
        display: none; }
      .proposalBuilder .options .sowBuilderSummary.has-products {
        /*@media (max-width: $breakpoint-small) {
          top:-90px;
          width:100%;

          + .filterOptions {
            margin-top:125px;
          }
        }*/ }
        .proposalBuilder .options .sowBuilderSummary.has-products > .cta {
          display: block; }
          .proposalBuilder .options .sowBuilderSummary.has-products > .cta .preview {
            display: block; }
      .proposalBuilder .options .sowBuilderSummary.preview-showing {
        border-color: #bfbfbf;
        z-index: 99999; }
        .proposalBuilder .options .sowBuilderSummary.preview-showing > .preview {
          display: block; }
    .proposalBuilder .options.sowBuilderEmpty > .search, .proposalBuilder .options.sowBuilderEmpty > .categories {
      width: 30%; }
    .proposalBuilder .options.sowBuilderEmpty .sowBuilderSummary {
      display: none; }
    .proposalBuilder .options:not(.sowBuilderEmpty) {
      justify-content: left; }
  .proposalBuilder .items {
    margin: 0 -15px;
    justify-content: center; }
    .proposalBuilder .items .product-container > div {
      /*&:before,
          &:after {
            position: absolute;
            z-index: 2;
            content: "";
            top: 50%;
            left: 10px;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background-color: $color-gray;
          }
          &:after {
            background: url(../assets/featured-icon.png) center center
            no-repeat;
            background-size: auto 22px;
          }*/ }
      .proposalBuilder .items .product-container > div .card .card-header {
        margin-bottom: 20px;
        background-color: transparent;
        border: 0; }
      .proposalBuilder .items .product-container > div .card .card-body {
        padding: 0 10px;
        position: relative;
        height: 75px;
        box-sizing: border-box; }
        .proposalBuilder .items .product-container > div .card .card-body .card-text {
          font-size: 11px;
          line-height: 15px;
          position: relative; }
          .proposalBuilder .items .product-container > div .card .card-body .card-text .description-short {
            display: block; }
          .proposalBuilder .items .product-container > div .card .card-body .card-text .description-full {
            display: none; }
      .proposalBuilder .items .product-container > div .card .card-footer {
        margin-top: 20px;
        background-color: transparent;
        border: 0; }
      .proposalBuilder .items .product-container > div:hover .card .card-body {
        overflow: auto; }
        .proposalBuilder .items .product-container > div:hover .card .card-body .card-text .description-short {
          display: none; }
        .proposalBuilder .items .product-container > div:hover .card .card-body .card-text .description-full {
          display: block; }
      .proposalBuilder .items .product-container > div .card {
        overflow: hidden;
        padding: 25px 15px;
        border: 1px solid #ccc;
        background-color: #fff;
        box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.025);
        line-height: 1.5;
        overflow: hidden;
        -moz-border-radius: .25rem;
        -webkit-border-radius: .25rem;
        border-radius: .25rem;
        /*border-top:5px solid $color-blue;*/
        /*&:before {
              position: absolute;
              z-index: 2;
              content: "";
              top: 0;
              left: 0;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 500px 0 0 210px;
              border-color: transparent transparent transparent $color-gray;
            }*/ }
        .proposalBuilder .items .product-container > div .card:hover {
          border-color: #c2c6cb;
          background-color: #fcfcfc;
          box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
          -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0 1px 8px;
          -moz-box-shadow: rgba(0, 0, 0, 0.1) 0 1px 8px; }
        .proposalBuilder .items .product-container > div .card:after {
          /*position: absolute;
              z-index: 2;
              content: "";
              top: 0;
              left: 0;
              width: 0;
              height: 0;
              border-style: solid;
              //border-width: 0 300px 220px 0;
              border-width: 15px 85px 0 0;
              border-color: $color-blue transparent transparent transparent;
              opacity: .5;*/ }
        .proposalBuilder .items .product-container > div .card .card-header {
          padding: 0;
          text-align: center;
          flex-grow: 1; }
          .proposalBuilder .items .product-container > div .card .card-header .card-title {
            padding: 0;
            color: #00afcc; }
          .proposalBuilder .items .product-container > div .card .card-header .priceTime {
            color: #999;
            font-size: 14px; }
            .proposalBuilder .items .product-container > div .card .card-header .priceTime span + span:before {
              color: #999; }
        .proposalBuilder .items .product-container > div .card .card-body .card-text {
          font-size: 11px;
          color: #666; }
        .proposalBuilder .items .product-container > div .card .card-footer {
          text-align: center;
          white-space: nowrap; }
          .proposalBuilder .items .product-container > div .card .card-footer button {
            background-color: #fff;
            color: #ff8400;
            border: 1px solid #ff8400;
            white-space: nowrap;
            text-align: center;
            text-decoration: none;
            border-radius: 3px;
            font-size: 14px;
            padding: 8.5px 20px;
            font-weight: 300; }
            .proposalBuilder .items .product-container > div .card .card-footer button:hover {
              background-color: #ff8400;
              color: #fff; }
            .proposalBuilder .items .product-container > div .card .card-footer button:focus {
              box-shadow: none; }
            .proposalBuilder .items .product-container > div .card .card-footer button + button {
              margin-left: 15px; }
            .proposalBuilder .items .product-container > div .card .card-footer button.added {
              background: #00afcc;
              position: relative;
              color: #fff;
              border-color: #00afcc; }
              .proposalBuilder .items .product-container > div .card .card-footer button.added:before {
                position: absolute;
                z-index: 1;
                top: -4px;
                left: -4px;
                content: "\f111";
                color: #fff;
                font: normal normal normal 14px/1 FontAwesome;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                text-shadow: 0.5px 0.5px 0 white; }
              .proposalBuilder .items .product-container > div .card .card-footer button.added:after {
                position: absolute;
                z-index: 2;
                top: -4px;
                left: -4px;
                content: "\f056";
                color: #000;
                font: normal normal normal 14px/1 FontAwesome;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                opacity: 0.9;
                -webkit-transition: opacity 200ms ease;
                -moz-transition: opacity 200ms ease;
                -o-transition: opacity 200ms ease;
                transition: opacity 200ms ease; }
              .proposalBuilder .items .product-container > div .card .card-footer button.added:hover {
                background: #00a6c2; }
                .proposalBuilder .items .product-container > div .card .card-footer button.added:hover:after {
                  opacity: 1; }
      .proposalBuilder .items .product-container > div:hover {
        opacity: 1; }
        .proposalBuilder .items .product-container > div:hover .card .card-footer {
          text-align: center; }
    .proposalBuilder .items .product-container.col-lg-12 > div .card .card-body {
      height: auto; }
      .proposalBuilder .items .product-container.col-lg-12 > div .card .card-body .card-text {
        font-size: 12px; }
        .proposalBuilder .items .product-container.col-lg-12 > div .card .card-body .card-text .description-short {
          display: none; }
        .proposalBuilder .items .product-container.col-lg-12 > div .card .card-body .card-text .description-full {
          display: block; }
    .proposalBuilder .items .product-container.col-lg-12 > div:hover .card .card-body {
      overflow: visible; }
    .proposalBuilder .items .product-container .sowBuilder {
      position: absolute;
      top: -10px;
      right: -10px;
      opacity: 0;
      pointer-events: none;
      -webkit-transition: opacity 200ms ease;
      -moz-transition: opacity 200ms ease;
      -o-transition: opacity 200ms ease;
      transition: opacity 200ms ease;
      /*button {
          position: relative;
          display: inline-block;
          color: #fff;
          white-space: nowrap;
          text-align: center;
          background: green;
          text-decoration: none;
          border-color: rgba(255, 255, 255, 0.75);
          border-radius: 3px;
          font-size: 11px;
          padding: 5px 12px 5px 10px !important;
          font-weight: 300;
          -webkit-transition: background 200ms ease;
          -moz-transition: background 200ms ease;
          -o-transition: background 200ms ease;
          transition: background 200ms ease;

          &:focus {
            outline: 0;
          }
        }*/ }
      .proposalBuilder .items .product-container .sowBuilder.active {
        opacity: 1;
        pointer-events: auto;
        z-index: 0; }
      .proposalBuilder .items .product-container .sowBuilder .added {
        background: #00afcc;
        position: relative;
        color: #fff;
        border-color: #00afcc;
        font-size: 11px;
        padding: 5px 12px 5px 10px !important; }
        .proposalBuilder .items .product-container .sowBuilder .added:before {
          position: absolute;
          z-index: 1;
          top: -4px;
          left: -4px;
          content: "\f111";
          color: #fff;
          font: normal normal normal 14px/1 FontAwesome;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          text-shadow: 0.5px 0.5px 0 white; }
        .proposalBuilder .items .product-container .sowBuilder .added:after {
          position: absolute;
          z-index: 2;
          top: -4px;
          left: -4px;
          content: "\f056";
          color: #000;
          font: normal normal normal 14px/1 FontAwesome;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          opacity: 0.9;
          -webkit-transition: opacity 200ms ease;
          -moz-transition: opacity 200ms ease;
          -o-transition: opacity 200ms ease;
          transition: opacity 200ms ease; }
        .proposalBuilder .items .product-container .sowBuilder .added:hover {
          background: #00a6c2; }
          .proposalBuilder .items .product-container .sowBuilder .added:hover:after {
            opacity: 1; }
  .proposalBuilder .error {
    text-align: center;
    font-style: italic;
    color: #999;
    padding-top: 10px;
    padding-bottom: 40px; }
  .proposalBuilder.preview-showing {
    position: relative; }
    .proposalBuilder.preview-showing:after {
      content: '';
      position: absolute;
      z-index: 9999;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #fafafa;
      opacity: .75;
      pointer-events: none; }
    .proposalBuilder.preview-showing .options > *:not(.sowBuilderSummary), .proposalBuilder.preview-showing .items {
      pointer-events: none; }

@media (max-width: 1400px) {
  .proposalBuilder .options .sowBuilderSummary > .cta .save.save:hover .label, .proposalBuilder .options .sowBuilderSummary > .cta .preview.save:hover .label, .proposalBuilder .options .sowBuilderSummary > .cta .add.save:hover .label, .proposalBuilder .options .sowBuilderSummary > .cta .added.save:hover .label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px; }
  .proposalBuilder .options .sowBuilderSummary > .cta .save.save:hover .docTypes, .proposalBuilder .options .sowBuilderSummary > .cta .preview.save:hover .docTypes, .proposalBuilder .options .sowBuilderSummary > .cta .add.save:hover .docTypes, .proposalBuilder .options .sowBuilderSummary > .cta .added.save:hover .docTypes {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-left: 0;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    left: 0;
    right: auto;
    transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    -webkit-box-shadow: inset -13px 0px 5px -10px rgba(0, 0, 0, 0.075);
    -moz-box-shadow: inset -13px 0px 5px -10px rgba(0, 0, 0, 0.075);
    box-shadow: inset -13px 0px 5px -10px rgba(0, 0, 0, 0.075); } }

@media (max-width: 1200px) {
  .proposalBuilder .options > .listGridToggle {
    display: none; } }

.modal-dialog.createProposalConfirm {
  top: 25%;
  text-align: center; }
  .modal-dialog.createProposalConfirm .modal-body {
    padding: 30px; }
  .modal-dialog.createProposalConfirm label {
    color: #333; }
  .modal-dialog.createProposalConfirm .actions {
    white-space: nowrap;
    margin-top: 15px; }
    .modal-dialog.createProposalConfirm .actions button {
      border: 0; }
      .modal-dialog.createProposalConfirm .actions button a {
        color: #fff; }
      .modal-dialog.createProposalConfirm .actions button.btn-primary {
        background-color: #ff8400; }
        .modal-dialog.createProposalConfirm .actions button.btn-primary:hover {
          background-color: #f57f00; }
      .modal-dialog.createProposalConfirm .actions button + button {
        margin-left: 15px; }

.modal-dialog.editProposal {
  max-width: 95%;
  margin-top: 23px; }
  .modal-dialog.editProposal .modal-header {
    border-bottom-color: #ddd;
    position: relative; }
    .modal-dialog.editProposal .modal-header .modal-title {
      color: #666;
      font-size: 18px;
      font-weight: 400;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%); }
    .modal-dialog.editProposal .modal-header .close {
      font-weight: 500; }

.modal-dialog .modal-body {
  background-color: #fafafa;
  -webkit-box-shadow: inset 0px 2px 2px 0px rgba(0, 0, 0, 0.025);
  -moz-box-shadow: inset 0px 2px 2px 0px rgba(0, 0, 0, 0.025);
  box-shadow: inset 0px 2px 2px 0px rgba(0, 0, 0, 0.025); }
  .modal-dialog .modal-body .proposalBuilder h3 {
    font-size: 24px;
    font-weight: 500; }
  .modal-dialog .modal-body .proposalBuilder > .container {
    padding-bottom: 25px; }
  .modal-dialog .modal-body .proposalBuilder .review > button {
    margin: 40px auto 0 auto;
    text-align: center;
    background-color: #00afcc;
    border: 0; }
    .modal-dialog .modal-body .proposalBuilder .review > button:hover {
      background-color: #00c5e6; }
    .modal-dialog .modal-body .proposalBuilder .review > button:focus {
      box-shadow: none; }
  .modal-dialog .modal-body .proposalBuilder .sowBuilderSummary > .cta .save:after {
    top: -1px;
    right: 6px; }
  .modal-dialog .modal-body .detail.container {
    padding-top: 0; }
    .modal-dialog .modal-body .detail.container .sowBuilderSummary > .cta .save:after {
      right: 8px; }
